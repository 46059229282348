import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, Toolbar, IconButton, Typography, Container, Button, Divider, Stack} from "@mui/material";
import {AppBar, Drawer, List, ListItem, ListItemButton, ListItemText} from "@mui/material";

// context
import {useUser} from "../context/UserContext";

// Icon
import MenuIcon from '@mui/icons-material/Menu';

// utils
import {decodeToken} from "../utils/decodeToken";

// 페이지 목록
interface PageList {
    name: string;
    path: string;
    isAdmin?: boolean;
    isAuth?: boolean;
}

const leftPageList: PageList[] = [
    {name: 'Home', path: '/', isAdmin: false},
    {name: 'Data', path: '/data', isAdmin: false},
    {name: 'Admin View', path: '/admin/data', isAdmin: true},
];

const rightPageList: PageList[] = [
    {name: 'Login', path: '/signin', isAuth: false},
    {name: 'Register', path: '/signup', isAuth: false},
    {name: 'My Page', path: '/user', isAuth: true},
    {name: 'Logout', path: '/signout', isAuth: true},
];

const serviceName = "KSTMD";

export default function ResponsiveAppBar() {
    const navigate = useNavigate();
    const {accessToken} = useUser();

    const [anchorElNav, setAnchorElNav] = React.useState<boolean>(false);
    const navigatePage = (path: string) => navigate(path);

    const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))  return;
        setAnchorElNav(state);
    };

    const filterAndRenderList = (pageList: PageList[], renderType: 'button' | 'listItem') => {
        const {username, permission} = decodeToken(accessToken);
        const isAdmin = permission === 'admin';
        const isAuth = username !== null;

        pageList = pageList.filter(page => {
            if (page.isAdmin !== undefined && page.isAdmin && !isAdmin) return false;
            if (page.isAuth !== undefined && page.isAuth !== isAuth) return false;
            return true;
        });

        return pageList.map((page) => {
            if (renderType === 'button') {
                return (
                    <Button
                        key={page.name}
                        onClick={() => navigatePage(page.path)}
                        sx={{ my: 2, color: 'white', display: 'inline' }}>
                        {page.name}
                    </Button>
                );
            } else {
                return (
                    <ListItem key={page.name} disablePadding>
                        <ListItemButton onClick={() => navigatePage(page.path)}>
                            <ListItemText primary={page.name} />
                        </ListItemButton>
                    </ListItem>
                );
            }
        });
    };

    const pcAppbar = (
        <>
            <Box onClick={() => navigatePage("/")} sx={{cursor: 'pointer',}}>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    sx={{
                        mr: 2,
                        fontWeight: 700,
                        color: 'inherit',
                        textDecoration: 'none',
                        display: { xs: 'none', md: 'flex' }
                    }}>
                    {serviceName}
                </Typography>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {filterAndRenderList(leftPageList, 'button')}
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {filterAndRenderList(rightPageList, 'button')}
            </Box>
        </>
    );

    const mobileAppBar = (
        <>
            <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => navigatePage("/")}
                sx={{
                    mr: 2,
                    fontWeight: 700,
                    display: { xs: 'block', md: 'none' },
                    color: 'inherit',
                    textDecoration: 'none',
                    flexGrow: 1,
                }}>
                {serviceName}
            </Typography>
            <Box sx={{ flexGrow: 0, display: { xs: 'block', md: 'none' } }}>
                <IconButton
                    size="large"
                    onClick={toggleDrawer(true)}
                    color="inherit">
                    {<MenuIcon/>}
                </IconButton>
                <Drawer
                    anchor='right'
                    open={anchorElNav}
                    onClose={toggleDrawer(false)}>
                    <Box
                        width="60vw"  height="100%"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}>
                        <Stack height="100%">
                            <Box
                                height="20vh"
                                marginBottom="2vh"

                                sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', backgroundColor: "#737373"}}>
                                <Stack>
                                    <Typography
                                        variant="h5"  component="a"
                                        color="white"
                                        sx={{ fontWeight: 700, textAlign: "center" }}>
                                        {serviceName}
                                    </Typography>
                                    <br/>
                                    <Typography
                                        variant="body1"
                                        color="white"
                                        sx={{ textAlign: "center" }}>
                                        안녕하세요, {"username"}님!
                                    </Typography>
                                </Stack>
                            </Box>

                            <Typography
                                variant="h6" component="a"
                                marginLeft="2vw"
                                sx={{ fontWeight: 700 }}>
                                Menu
                            </Typography>
                            <Divider />
                            <List sx={{ flexGrow: 1, marginLeft: "2vw"}}>
                                {filterAndRenderList(leftPageList, 'listItem')}
                            </List>
                            <Divider />
                            <List sx={{ flexGrow: 0, marginLeft: "2vw" }}>
                                {filterAndRenderList(rightPageList, 'listItem')}
                            </List>
                        </Stack>
                    </Box>
                </Drawer>
            </Box>
        </>
    );

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* PC 화면 */}
                    {pcAppbar}

                    {/* 모바일 화면 */}
                    {mobileAppBar}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
