import  React from 'react';
import { useNavigate } from "react-router-dom";
import {Box, Button, Container, Link, Divider, Stack, TextField, FormControlLabel, Checkbox} from "@mui/material";
import { Card, ModalClose } from "@mui/joy";
import { toast } from "react-toastify";

// components
import { Loading } from "../components/Loading";

// style
import { toastOption } from "../utils/toastOptions";

// utils
import axiosInstance from "../utils/axiosInstance";

interface APIData {
    result: string,
    message: string;
}


// TODO : 회원가입 로직 수정 (UI만 완성됨)
export default function SignUpPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [username, setUsername] = React.useState<string>("");
    const [userEmail, setUserEmail] = React.useState<string>("");
    const [userPw, setUserPw] = React.useState<string>("");
    const [repeatUserPw, setRepeatUserPw] = React.useState<string>("");
    const [agree, setAgree] = React.useState<boolean>(false);

    const [usernameError, setUsernameError] = React.useState<boolean>(false);
    const [userEmailError, setUserEmailError] = React.useState<boolean>(false);
    const [userPwError, setUserPwError] = React.useState<boolean>(false);
    const [repeatUserPwError, setRepeatUserPwError] = React.useState<boolean>(false);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^[가-힣]{2,}$/;
        const inputValue = e.target.value;

        setUsername(e.target.value);
        if(!regExp.test(inputValue) || inputValue.length === 0){
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }
    const handleUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        const inputValue = e.target.value;

        setUserEmail(e.target.value);
        if(!regExp.test(inputValue) || inputValue.length > 20 || inputValue.length === 0){
            setUserEmailError(true);
        } else {
            setUserEmailError(false);
        }
    }
    const handleUserPwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        const inputValue = e.target.value;

        setUserPw(e.target.value);
        if(!regExp.test(inputValue) || inputValue.length === 0){
            setUserPwError(true);
        } else {
            setUserPwError(false);
        }
    }
    const handleRepeatUserPwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        setRepeatUserPw(e.target.value);
        if(inputValue.length === 0 || inputValue !== userPw){
            setRepeatUserPwError(true);
        } else {
            setRepeatUserPwError(false);
        }
    }


    const handleLogin = async () => {
        setLoading(true);

        if(userEmailError || userPwError || repeatUserPwError){
            setLoading(false);
            toast.error('입력값을 확인해주세요.', toastOption);
            return;
        }

        try {
            const postData = {
                "username": username,
                "email": userEmail,
                "password": userPw,
            }
            const response = await axiosInstance.post("/signup", postData);
            if (response.status === 200) {
                const result: APIData = response.data as APIData;
                if(result.result === "success"){
                    toast.success(<>회원가입이 완료되었습니다.<br/>로그인 후 서비스를 이용해주세요.</>, toastOption);
                    navigate("/");
                } else {
                    toast.error(result.message, toastOption);
                }
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
            toast.error('서버와 통신에 실패하였습니다.', toastOption);
            return;
        }
        return;
    };

    return (
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh">
                <Loading open={loading} status={"회원가입 중입니다..."}/>
                <Card variant="outlined" sx={{width: "45vh"}}>
                    <ModalClose variant="soft" onClick={() => window.history.back()}></ModalClose>
                    <Stack style={{alignItems: "center"}}>
                        <h1 style={{marginBottom: 0}}>KSTMD Register</h1>
                        <p>서비스 이용을 위해 회원가입을 진행합니다.</p>
                    </Stack>
                    <Stack spacing={2} style={{alignItems: "center"}}>
                        <TextField
                            label="Username" variant="outlined"
                            value={username} onChange={handleUsernameChange}
                            error={usernameError} helperText={usernameError ? "Username을 2자 이상 한국어로만 입력해주세요." : ""}
                            fullWidth/>
                        <Divider/>
                        <TextField
                            label="Eamil" variant="outlined"
                            value={userEmail} onChange={handleUserEmailChange}
                            error={userEmailError} helperText={userEmailError ? "Email을 정확하게 입력해주세요." : ""}
                            fullWidth/>
                        <Divider/>
                        <TextField
                            label="Password" variant="outlined" type="password"
                            value={userPw} onChange={handleUserPwChange}
                            error={userPwError} helperText={userPwError ? "최소 8자, 하나 이상의 문자 및 숫자를 포함해야 합니다." : ""}
                            fullWidth/>
                        <TextField
                            label="Repeat Password" variant="outlined" type="password"
                            value={repeatUserPw} onChange={handleRepeatUserPwChange}
                            error={repeatUserPwError} helperText={repeatUserPwError ? "Password와 일치하지 않습니다." : ""}
                            fullWidth/>
                        <Divider/>
                        <FormControlLabel
                            control={<Checkbox checked={agree} onChange={() => setAgree(!agree)} name="checkedA" />}
                            label={<><Link href="https://naver.com" target="_blank" rel="noopener noreferrer">회원 약관</Link>에 동의합니다.</>}
                        />

                        <Button variant="contained" onClick={handleLogin} disabled={!agree} fullWidth>
                            회원가입
                        </Button>
                        {/*<p>계정이 이미 있나요? <Link href="/signin">로그인</Link></p>*/}
                    </Stack>
                </Card>
            </Box>
        </Container>
    );
}