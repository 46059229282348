import React, { useCallback, useEffect } from 'react';
import { Box, Container, Skeleton, Stack } from "@mui/material";
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Card } from "@mui/joy";
import { toast } from "react-toastify";

// Modal
import {ViewAllMessageModal} from "../components/modals/viewAllMessage";

// style
import styles from "../style/App.module.css";

// utils
import axiosInstance from "../utils/axiosInstance";
import { toastOption } from "../utils/toastOptions";

interface MessageData {
    index: number,
    message: string,
    target: number
}

interface APIData {
    result: string,
    data: MessageData[]
}

const TableRowsLoader = (rowsNum: number) => {
    return [...Array(rowsNum)].map((row, index) => (
        <TableRow key={index}>
            <TableCell component="th" scope="row"><Skeleton animation="wave" variant="text" /></TableCell>
            <TableCell><Skeleton animation="wave" variant="text" /></TableCell>
            <TableCell><Skeleton animation="wave" variant="text" /></TableCell>
        </TableRow>
    ));
};

export default function DataViewPage() {
    const [messageViewTitle, setMessageViewTitle] = React.useState<string>("");
    const [messageViewContent, setMessageViewContent] = React.useState<string>("");
    const [messageViewModal, setMessageViewModal] = React.useState<boolean>(false);
    const [apiDataList, setAPIDataList] = React.useState<MessageData[]>([]);


    const truncateString = (str: string) => {
        const maxLength = 100;
        if (str.length > maxLength) return str.substring(0, maxLength - 3) + "...";
        else return str;
    }

    const closeAllMessageViewModal = () => {
        setMessageViewModal(false);
    }

    const openModal = (index: number, content: string) => {
        setMessageViewTitle(`Message ${index}`);
        setMessageViewContent(content);
        setMessageViewModal(true);
    }

    const fetchData = useCallback(async () => {
        try {
            const response = await axiosInstance.get("/data");
            if (response.status === 200) {
                const result: APIData = response.data;
                if (result.result === "success") {
                    setAPIDataList(result.data);
                } else {
                    throw new Error("서버 값 오류");
                }
            }
        } catch (e) {
            toast.error('권한이 없습니다.', toastOption);
            console.error(e);
        }
    }, [toastOption]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Stack spacing={0} style={{backgroundColor: "#7f7f7f"}}>
            <ViewAllMessageModal open={messageViewModal} onClose={closeAllMessageViewModal} title={messageViewTitle} content={messageViewContent}/>
            <Box className={styles.banner} style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)`}}>
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     minHeight="100%">
                    <Stack style={{alignItems: "center"}}>
                        <h1>KSTMD</h1>
                        <h2>Korean Spam Text Message Dataset</h2>
                    </Stack>
                </Box>
            </Box>
            <Box className={styles.section}>
                <Container>
                    <Card style={{height: '100%'}} variant="outlined">
                        <Stack style={{alignItems: "center"}}>
                            <h1 style={{marginBottom: 0}}>최근 Data</h1>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 600 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: 5 }}>index</TableCell>
                                            <TableCell>Message</TableCell>
                                            <TableCell align="right">target</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apiDataList.length === 0 ?
                                            TableRowsLoader(5)
                                            :
                                                apiDataList.map((data) => (
                                                    <TableRow
                                                        key={data.index}
                                                        sx={{cursor: 'pointer' }}
                                                        onClick={() => {openModal(data.index, data.message)}}>
                                                        <TableCell component="th" scope="row">{data.index}</TableCell>
                                                        <TableCell>{truncateString(data.message)}</TableCell>
                                                        <TableCell align="right">{data.target}</TableCell>
                                                    </TableRow>
                                                ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Card>
                </Container>
            </Box>
        </Stack>
    )
}